import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby"
import Img, { FluidObject } from "gatsby-image";

import { Divider, Stack, styled, Typography, Grid, Container, ImageList, ImageListItem, ImageListItemBar, Paper, ButtonBase } from '@mui/material';
import ContentWithImage from "../ContentWithImage";
import AccessoryDetails from "./AccessoryDetails";

const Root = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingLeft: 20,
  paddingRight: 20,
});


const ImgItem = styled(Img)(({ theme }) => ({
  width: '100%',


  [ theme.breakpoints.down('sm') ]: {
    width: '100%',
  },
  [ theme.breakpoints.up('md') ]: {
    height: '100%',
  }
}));

const Item = styled(Paper)({
  flex: 1,
  flexShrink: 0,
  flexGrow: 1,
  borderRadius: 10,
  overflow: 'hidden',
});

const Button = styled(ButtonBase)(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    width: '100%',
    height: '100%',
    alignItems: 'center',
    flex: 1,
    flexShrink: 0,
    flexGrow: 1,
    padding: 10,
    [ theme.breakpoints.down('sm') ]: {
      padding: 5,
    },
  };
});

const Spacer = styled('div')(({ theme }) => (
  {
    width: 20,
    height: 20,
    flexShrink: 0,
    [ theme.breakpoints.down('sm') ]: {
      width: 10,
      height: 10,
    },
  }
));

const Title = styled('div')({
  maxWidth: '100%',
  padding: '0px 5px',
})


const ImgPair = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
})

const AccessoryNames: { [ key: string ]: string } = {
  "zajigalka": "Lighter (aka Zajigalka)",
  "water": "Water Bottle (aka Butulka)",
  "vosku_cep": "Gold Chain (aka Vosku tsep)",
  "watch": "Gold Watch (aka Voskuc zham)",
  "tzbex_3": "Beads (aka Tzbex)",
  "shemushka": "Sunflower Seeds (aka Shemushka)",
  "plan": "Joint (aka Plan)",
  "matchstick": "Matchstick (aka Lucku chop)",
  "matniq": "Gold Ring (aka Matniq)",
  "card_blot": "Playing Cards (aka Bloti card)",
  "mag": "Stereo (aka Mag)",
  "limonad": "Soda (aka Limonad)",
  "kepka": "A Cap (aka Kepka)",
  "golden_teeth": "Golden Tooth (aka Vosku atam)",
  "cigaret": "Cigarette (aka Papiroz)",
  "balniq": "Key (aka Balniq)",
  "cap": "A Cap (aka Kepka)",
  "aparat": "Camera (aka Aparat)",
  "bread": "Bread (aka Matnaqash)",
  "aknots": "Sunglasses (aka Aknots)",
  "nardi": "Backgammon (aka Nardi)",
  "belt": "Belt (aka Xaish)",
  "ball": "Ball (aka Gndak)",
  "manghal": "Grill (aka Manghal)",
  "brduch": "Wrap Sandwich (aka Brduch)",
  "xorovats": "Shish Kebab (aka Xorovats)",
}



interface CurrImage {
  image: FluidObject;
  title: string;
}


const Features: React.FC = () => {
  const imagesData = useStaticQuery(graphql`
    query {
      accessories: allFile(filter: {relativeDirectory: {in: "accessories"}}) {
        edges {
          node {
            publicURL
            name
            childImageSharp {
              fluid(maxWidth: 600, webpQuality: 90) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  `);



  const [ images, setImages ] = useState<any[]>([]);

  useEffect(() => {
    if (!imagesData) {
      return;
    }


    const data = [ ...imagesData.accessories.edges ];
    const list: any = [];

    while (data.length > 0) {
      list.push([]);
      const index = list.length - 1;
      const item1 = data.shift();

      list[ index ].push({
        name: item1.node.name,
        publicURL: item1.node.publicURL,
        fluid: item1.node.childImageSharp.fluid,
      });

      const item2 = data.shift();

      if (item2) {
        list[ index ].push({
          name: item2.node.name,
          publicURL: item2.node.publicURL,
          fluid: item2.node.childImageSharp.fluid,
        });
      }
    }
    setImages(list);

  }, [ imagesData ]);


  const [ currImage, setCurrImage ] = useState<CurrImage | null>(null);

  const openImgDialog = (title: string, image: FluidObject) => () => {
    setCurrImage({
      image, title
    });
  }

  const closeImgDialog = () => {
    setCurrImage(null);
  }


  const getTitle = (name: string) => {
    return AccessoryNames[ name ] ? AccessoryNames[ name ] : name;
  }

  return (
    <Root>
      <Typography variant="button" align="center" paragraph className="accessory-list" color="text.secondary">
        Some of the accessories that Hayape's could have
      </Typography>


      <Container maxWidth="md">
        {
          images.map((pair, index1) => {
            return (
              <React.Fragment key={index1}>
                <ImgPair>
                  {
                    pair.map((img: any, index2: number) => {
                      const title = getTitle(img.name as string);
                      return (
                        <React.Fragment key={index2}>
                          <Item data-aos="fade-up">
                            <Button onClick={openImgDialog(title, img.fluid)}>
                              <ImgItem fluid={img.fluid} />
                              <Title>
                                <Typography variant="body2" noWrap>{title}</Typography>
                              </Title>
                            </Button>
                          </Item>
                          {
                            index2 == 0 ? <Spacer /> : null
                          }
                          {
                            index1 == images.length - 1 && pair.length === 1 ? <Item data-aos="fade-up"/> : null
                          }
                        </React.Fragment>
                      );
                    })
                  }
                </ImgPair>
                <Spacer />
              </React.Fragment>
            )

          })
        }

        {/* <ImageList sx={{ overflow: 'visible' }}>
            {
              images.accessories.edges.map((item: any) => {
                const title = getTitle(item.node.name as string);
                return (

                  <ImageListItem key={item.node.publicURL} data-aos="fade-up" sx={{ textAlign: 'center' }}>
                    <ImgItem fluid={item.node.childImageSharp.fluid} onClick={{}} />
                    <ImageListItemBar
                      title={title}
                    />
                  </ImageListItem>

                );
              })
            }
          </ImageList> */}

      </Container>

      <AccessoryDetails
        open={Boolean(currImage)}
        onClose={closeImgDialog}
        title={currImage?.title}
        image={currImage?.image}
      />

    </Root>
  );
};

export default Features;