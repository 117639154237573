import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Link, Paper, Stack, styled, Typography } from '@mui/material';
import { actions as walletActions } from '../services/wallet';
import MintDialog from "./MintDialog";
import MintPendingDialog from "./MintPendingDialog";
import Countdown, { CountdownRenderProps } from 'react-countdown';
import { RootState } from "../store";
import { padNumber } from "../utils/padNumber";

const Root = styled(Paper)({
  textAlign: 'center',
  padding: 40,
  borderRadius: 20,
  backgroundColor: '#123e00',
  border: '5px solid rgb(255,255, 255, 0.36)',
});

const PresaleMint: React.FC = () => {
  const stats = useSelector((state: RootState) => state.stats);
  const [ mintModalOpen, setMintModalOpen ] = useState(false);
  const [ mintPendingOpen, setMintPendingOpen ] = useState(false);

  const dispatch = useDispatch();

  const onMintClick = () => {
    dispatch(
      walletActions.connectWallet({
        onConnected: () => {
          setMintModalOpen(true);
        }
      })
    );
  }

  const closeMintModal = () => {
    setMintModalOpen(false);
  }

  const closeMintPending = () => {
    setMintPendingOpen(false);
  }

  const onMintStarted = () => {
    closeMintModal();
    setMintPendingOpen(true);
  }

  const renderer = (args: CountdownRenderProps) => {
    const hours = padNumber(args.hours, 2);
    const minutes = padNumber(args.minutes, 2);
    const seconds = padNumber(args.seconds, 2);
    return (
      <Stack direction="row" spacing={3}>
        {
          args.days > 0 ? <Typography variant="h5" sx={{ color: '#8ab4f8' }}>{args.days} Days</Typography> : null
        }
        <Typography variant="h5" sx={{ color: '#fcd56c' }}>{hours} Hours</Typography>
        <Typography variant="h5" sx={{ color: '#f28b82' }}>{minutes} Minutes</Typography>
        {
          args.days === 0 ? <Typography variant="h5" sx={{ color: '#81c995' }}>{seconds} Seconds</Typography> : null
        }
      </Stack>
    )
  };

  return (
    <Stack direction="column">
      <Root variant="outlined">
        <Typography variant="h6">PRE-SALE</Typography>
        <Typography sx={{ mb: 2 }}>only whitelisted wallets can mint now.</Typography>
        <Button variant="contained" color="secondary" size="large" onClick={onMintClick}>
          Mint Now ({stats.currentPrice} eth)
        </Button>

        <MintDialog open={mintModalOpen} onClose={closeMintModal} onMintStarted={onMintStarted} />
        <MintPendingDialog open={mintPendingOpen} onClose={closeMintPending} />

        <Box sx={{ mt: 5, textAlign: 'center' }}>
          <Typography>pre-sale ends in</Typography>
          <Countdown
            date={new Date(process.env.GATSBY_PRESALE_END_DATE as string)}
            renderer={renderer}
          />
        </Box>
      </Root>

      <Stack spacing={1} justifyContent="center" alignItems="center" sx={{mt: 3}} direction={{
        xs: 'column',
        sm: 'row',
        md: 'row',
        lg: 'row',
      }}>
        <Typography variant="h6">View collection on:</Typography>
        <Link variant="h6" href="https://opensea.io/collection/hayape-nft" target="_blank">OpenSea,</Link>
        <Link variant="h6" href="https://rarible.com/hayape-nft/items" target="_blank">Rarible,</Link>
        <Link variant="h6" href="https://looksrare.org/collections/0xC65973CA4658a5E06995d32c478c46B85552043b" target="_blank">LooksRare</Link>
      </Stack>
    </Stack>
  );
};

export default PresaleMint;