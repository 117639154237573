import React, { ReactNode } from "react";

import { Divider, styled, Typography } from '@mui/material';
import { BigNumber } from "bignumber.js";

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import { useSelector } from "react-redux";
import { RootState } from "../store";

const Root = styled('div')({
  marginTop: 40,
});



const PricingTimeline: React.FC = () => {
  const state = useSelector((state: RootState) => state.stats);

  const { currentRange, maxRanges, tokensPerRange, priceIncrement, startPrice } = state;

  const generateTimeline = () => {
    const initialPrice = new BigNumber(startPrice);
    const increment = new BigNumber(priceIncrement);

    const timeline: ReactNode[] = [
    ];

    for (let i = 0; i < maxRanges; i++) {
      let range = `${i*tokensPerRange}-${i*tokensPerRange + tokensPerRange - 1}`;

      const price = initialPrice.plus(increment.times(i)).toString();
      timeline.push(
        <TimelineItem key={i}>
          <TimelineOppositeContent color="text.secondary">
            {range} minted
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            {
              i + 1 < maxRanges ? <TimelineConnector /> : null
            }
          </TimelineSeparator>
          <TimelineContent>{price} eth</TimelineContent>
        </TimelineItem>
      )
    }
    return timeline;

  }

  return (
    <Root>
      <Divider sx={{ mb: 3 }} />
      <Typography paragraph align="center" variant="caption">
        Hurry up, as users mint HAYAPE's, the pricing will increase the following way:
      </Typography>


      <Timeline>
        {
          generateTimeline()
        }

      </Timeline>
      <Divider sx={{ mt: 3 }} />
    </Root>
  );
};

export default PricingTimeline;