import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby"
import { FluidObject } from "gatsby-image";
import { styled } from '@mui/material';

import useResizeObserver from "use-resize-observer";

import BgSlider from "./BgSlider";

const Root = styled('div')(({theme}) => ({
  margin: '32px auto',
  boxSizing: 'content-box',
  borderTop: `1px solid ${theme.palette.divider}`,
  borderBottom: `1px solid ${theme.palette.divider}`,
  paddingTop: 4,
  paddingBottom: 4,
  overflow: 'hidden',
  width: '100%',
  height: '20vh',
  position: 'relative',
}));

const CoverLeft = styled('div')({
  position: 'absolute',
  height: '100%',
  width: 200,
  backgroundImage: 'linear-gradient(to right, rgba(0, 0, 0, 1) , rgba(0, 0, 0, 0.2))',
  top: 0,
  left: 0,
});
const CoverRight = styled('div')({
  position: 'absolute',
  height: '100%',
  width: 200,
  backgroundImage: 'linear-gradient(to left, rgba(0, 0, 0, 1) , rgba(0, 0, 0, 0.2))',
  top: 0,
  right: 0,
});


let timer: any;
let inc = 1;

const Carousel: React.FC = () => {
  const imgData = useStaticQuery(graphql`
    query {
      items: allFile(
        filter: {relativeDirectory: {in: "carousel"}}
        sort: {fields: name}
      ) {
        edges {
          node {
            publicURL
            name
            childImageSharp {
              fluid(maxWidth: 600, webpQuality: 90) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  `);


  const { ref, width = 0, height = 0 } = useResizeObserver<HTMLDivElement>();

  const itemSize = height;

  const [ index, setIndex ] = useState(0);
  const [images, setImages] = useState<FluidObject[]>([]);

  useEffect(() => {
    timer = setInterval(() => {
      const val = index + inc;
      if (val + 1 === images.length && inc > 0) {
        inc = -1;
      }
      else if (val - 1 < 0 && inc < 0) {
        inc = 1;
      }

      setIndex(val);
    }, 3000);

    return () => {
      clearInterval(timer);
    }
  }, [ index ]);

  useEffect(() => {
    setImages(
      imgData.items.edges.map((item: any) => item.node.childImageSharp.fluid)
    );

  }, [imgData?.items?.edges]);

  
  const coverWidth = width / 2 - (itemSize / 2);

  return (
    <Root ref={ref}>
      <BgSlider
        containerWidth={width}
        containerHeight={height}
        itemSize={itemSize}
        index={index % images.length}
        images={images}
      />
      <CoverLeft style={{width: coverWidth}}/>
      <CoverRight style={{width: coverWidth}}/>
      {/* <ApeImg index={index} direction={inc > 0 ? 'right' : 'left'} size={itemSize} /> */}
    </Root>
  );
};

export default Carousel;