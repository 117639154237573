import React from "react";

import { Dialog, DialogContent, DialogContentText, DialogTitle, IconButton, Slide, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from "@mui/material/transitions";
import Img, { FluidObject } from "gatsby-image";



const ImgItem = styled(Img)(({ theme }) => ({
  maxWidth: 600,
  width: '100%',
}));

const CloseButton = styled(IconButton)({
  position: 'absolute',
  top: 5,
  right: 5,
})

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


interface Props {
  open: boolean;
  onClose: () => void;
  title?: string;
  image?: FluidObject;
}

const AccessoryDetails: React.FC<Props> = ({ open, onClose, title, image }) => {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      fullWidth
      maxWidth="sm"
    >
      {
        image ? <ImgItem fluid={image as FluidObject} /> : null
      }
      <DialogContent>
        <DialogContentText>
          {title}
        </DialogContentText>
      </DialogContent>
      <CloseButton onClick={onClose}>
        <CloseIcon />
      </CloseButton>
    </Dialog>
  );
};

export default AccessoryDetails;