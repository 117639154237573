import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Layout from '../components/Layout/Layout';
import HomeHero from '../components/Hero';
import Counts from '../components/Counts';
import Carousel from '../components/Carousel/Carousel';
import AccessoryList from '../components/Accesorries/AccessoryList';
import Faq from '../components/Faq';

import PricingTimeline from '../components/PricingTimeline';
import About from '../components/About/About';

export default function Index() {
  return (
    <Layout title="HayApe NFT">
      <HomeHero />
      <Container maxWidth="lg">
        <Typography variant="overline" align="center" sx={{ mt: 2 }} component="div">
          Ready to Join the Hayape club?
        </Typography>

        <Counts />
      </Container>

      <Carousel />

      <Container maxWidth="lg" sx={{ mt: 2, mb: 10 }}>
        <Typography align="center" variant="h5" color="text.secondary">
          HayApe is created for fun, in reference to the "QYARTU" culture and lifestyle of 90's.
        </Typography>

        <PricingTimeline />

      </Container>
      <AccessoryList />

      <Faq />

      <About />
    </Layout>
  );
}