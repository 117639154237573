import React, { useState } from "react";
import Countdown, { CountdownRenderProps } from 'react-countdown';

import { Button, Paper, Stack, styled, Typography, Box } from '@mui/material';
import WhiteListDialog from "./WhiteListDialog";
import { padNumber } from "../utils/padNumber";

const Root = styled(Paper)({
  padding: 40,
  borderRadius: 20,
});

const ComingSoon: React.FC = () => {

  const [ open, setOpen ] = useState(false);

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const renderer = (args: CountdownRenderProps) => {
    const hours = padNumber(args.hours, 2);
    const minutes = padNumber(args.minutes, 2);
    const seconds = padNumber(args.seconds, 2);
    return (
      <Stack direction="row" spacing={3}>
        {
          args.days > 0 ? <Typography variant="h5" sx={{ color: '#8ab4f8' }}>{args.days} Days</Typography> : null
        }
        <Typography variant="h5" sx={{ color: '#fcd56c' }}>{hours} Hours</Typography>
        <Typography variant="h5" sx={{ color: '#f28b82' }}>{minutes} Minutes</Typography>
        {
          args.days === 0 ? <Typography variant="h5" sx={{ color: '#81c995' }}>{seconds} Seconds</Typography> : null
        }
      </Stack>
    )
  };

  return (
    <Root variant="outlined">
      <Stack direction="column" alignItems="center">
        <Typography variant="h6" align="center" gutterBottom>Coming Soon</Typography>
        <Typography align="center">Submit your Ethereum wallet address to our whitelist, <br />which will give you exclusive early access to pre-sale minting.</Typography>
        <Button sx={{ mt: 2 }} color="secondary" variant="contained" onClick={openDialog}>Join The Whitelist</Button>

        <Box sx={{ mt: 5, textAlign: 'center' }}>
          <Typography>presale starts in</Typography>
          <Countdown
            date={new Date(process.env.GATSBY_PRESALE_START_DATE as string)}
            renderer={renderer}
          />
        </Box>
      </Stack>



      <WhiteListDialog open={open} onClose={closeDialog} />
    </Root>
  );
};

export default ComingSoon;