import React, { useEffect, useState } from "react";
import Img, { FluidObject } from "gatsby-image";
import { styled } from '@mui/material';



const Root = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  position: 'relative',
  // backgroundColor: '#ccc',
  transition: 'transform 500ms',
});


const Item = styled('div')({
  // border: '1px solid #000',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  boxSizing: 'border-box',
  flex: '1, 1,1',
});


const CoverLeft = styled('div')({
  position: 'absolute',
  height: 100,
  width: 200,
  backgroundColor: 'rgba(0, 0, 0, 0.3)',
  top: 0,
  left: 0,
});

const CoverRIght = styled('div')({
  position: 'absolute',
  height: 100,
  width: 200,
  backgroundColor: 'rgba(0, 0, 0, 0.3)',
  top: 0,
  right: 0,
});


interface Props {
  containerWidth: number;
  containerHeight: number;
  itemSize: number;
  index: number;
  images: FluidObject[],
}



const BgSlider: React.FC<Props> = ({ containerWidth, containerHeight, index, itemSize, images }) => {
  
  const list = [
    ...images,
    ...images,
    ...images,
  ];

  
  const itemPos = list.length / 3 * itemSize + (index * itemSize);
  const centerPos = containerWidth / 2 - (itemSize / 2);
  const scrollPos = itemPos - centerPos;

  return (
    <Root style={{ width: list.length * itemSize, height: containerHeight, transform: `translate(-${scrollPos}px, 0px)` }}>
      {
        list.map((val, index) => (
          <Img key={index} fluid={list[index]} style={{ width: itemSize, height: itemSize }}/>
          // <Item key={index} style={{ width: itemSize, height: itemSize }}>Item #{index}</Item>
        ))
      }
    </Root>
  );
};

export default BgSlider;