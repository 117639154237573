import React from "react";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Container, Divider, styled } from '@mui/material';

const Root = styled('div')({
  marginTop: 20,
  paddingTop: 60,
});

const Faq: React.FC = () => {
  return (
    <Root className="faq-section">
      <Container maxWidth="md">
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography>What is an NFT?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              An NFT or Non-fungible Token, is a uniquely coded unit of data, usually a piece of art that you can buy to keep or trade. This piece of art and all of its ownership data (maker, first buyer, all trade info) is stored within a blockchain on IPFS.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography>What is blockchain?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Blockchain is basically a digital ledger that includes all transaction information for an NFT or piece of cryptocurrency that is written and stored in such a way that it is impossible to change or alter. Blockchain can also be found in games that use cryptocurrency or NFTs for in game purchases or trades.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography>What makes NFTs rare or valuable?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              There are only 9,999 HayApe NFTs available in the world. NFTs are created using a unique and random combination of factors like hair, background, accessories, etc. While every NFT is one of a kind, certain combinations will contain an accessory, background, or feature that can only be seen in a very small percentage of a NFT collection.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography>What can I do with my HayApe?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              You can do whatever you like such as trade, sell, or keep your HayApe which has a non-exclusive license. Your HayApe also acts as a membership token to the virtual Gazebo (aka Bisetka) coming soon.
            </Typography>
          </AccordionDetails>
        </Accordion>

      </Container>

    </Root>
  );
};

export default Faq;