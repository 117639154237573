import React from "react";

import { Stack, styled, Typography } from '@mui/material';

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image";
import Typed from 'react-typed';


import MintButton from "./MintButton";
import ComingSoon from "./ComingSoon";
import PresaleMint from "./PresaleMint";


const Root = styled('div')(({ theme }) => ({
  position: 'relative',
  // backgroundColor: theme.palette.primary.main,
}));

const BgContainer = styled('div')({
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  position: 'absolute',
  overflow: 'hidden',
})


const BgImg = styled('div')({
  "@keyframes zoomIn": {
    '100%': {
      transform: 'scale(1.2)'
    }
  },
  animationFillMode: 'forwards',
  animationDuration: '20s',
  animationDelay: '0.2s',
  animationName: 'zoomIn',
  animationTimingFunction: 'ease-out',
  position: 'absolute',
  backgroundImage: 'url("/moon.jpg"), -webkit-linear-gradient(top, rgba(0,0,0,0.7), rgba(0,0,0,0.7))',
  backgroundBlendMode: 'overlay',
  backgroundSize: 'cover',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
})

const HeroContent = styled('div')(
  ({ theme }) => ({
    // backgroundColor: '#1b1b1b',
    // backgroundColor: 'red',
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '100%',
    display: "flex",
    flexDirection: 'column',
    justifyContent: "flex-start",
    alignItems: "center",
    position: 'relative',
  })
);

const GroupImg = styled(Img)(() => ({
  width: '100%',
  maxWidth: 900,
  marginTop: 30,
}))

const HeroText = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  textTransform: 'uppercase',
  fontSize: 30,
  [ theme.breakpoints.down('sm') ]: {
    fontSize: 16,
  },
}));


const TypedText = styled(Typed)(({ theme }) => ({
  fontSize: '1.5rem',
  lineHeight: 1.6,
  fontWeight: 500,
  textAlign: 'center',
  color: '#8ab4f8',
}));


const HomeHero: React.FC = () => {
  const imgData = useStaticQuery(graphql`
    query {
      heroBanner: file(relativePath: {eq: "home/banner.png"}) {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1000, webpQuality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  return (
    <Root>
      <BgContainer>
        <BgImg />
      </BgContainer>
      <HeroContent>
        <Stack direction="column" spacing={3} sx={{ maxWidth: 700, flex: 1, flexGrow: 1, }} alignItems="center">
          <HeroText>
            <u>Hayape's</u> are finally here, but don't confuse them with Apes
          </HeroText>

          <TypedText
            typeSpeed={35}
            backSpeed={50}
            backDelay={2500}
            strings={[
              "HayApe's are never bored they are cool ",
              "HayApe's are never bored they are unique ",
              "HayApe's are never bored they are qyartu ",
              "HayApe's are never bored they are fun ",
            ]}
          />

          {
            process.env.GATSBY_SALE_MODE === 'coming_soon' ? <ComingSoon /> : null
          }

          {
            process.env.GATSBY_SALE_MODE === 'presale' ? <PresaleMint /> : null
          }

          {
            process.env.GATSBY_SALE_MODE === 'public_sale' ? <MintButton /> : null
          }

        </Stack>
        <GroupImg fluid={imgData.heroBanner.childImageSharp.fluid} />

      </HeroContent>
    </Root>
  );
};

export default HomeHero;