import React, { useEffect } from "react";
import CountUp from 'react-countup';
import { Box, Skeleton, Stack, styled, Typography } from '@mui/material';
import { useSelector } from "react-redux";
import { RootState } from "../store";
// import { testContract } from "../web3/contract";


const Root = styled('div')({

});

const Counts: React.FC = () => {

  const state = useSelector((state: RootState) => state.stats);

  return (
    <Root>
      {
        state.loading ? (
          <Stack direction="row" justifyContent="center">
            <Skeleton width={250} height={40} />
          </Stack>
        ) : (
          <React.Fragment>
            {
              process.env.GATSBY_SALE_MODE === 'coming_soon' ? (
                <CountUp start={0} end={9999} delay={0}>
                  {({ countUpRef }) => (
                    <Stack direction="row" spacing={1} justifyContent="center" sx={{ mt: 2 }}>
                      <Typography variant="h6">Unique NFT's will be available:</Typography>
                      <Typography variant="h6" ref={countUpRef}> {state.totalSupply}</Typography>
                    </Stack>
                  )}
                </CountUp>
              ) : (
                <CountUp start={0} end={state.totalSupply - state.circulatinSupply} delay={0}>
                  {({ countUpRef }) => (
                    <Stack direction="row" spacing={1} justifyContent="center" sx={{ mt: 2 }}>
                      <Typography variant="h6">Unique NFT's available:</Typography>
                      <Typography variant="h6" ref={countUpRef} />
                      <Typography variant="h6">of {state.totalSupply}</Typography>
                    </Stack>
                  )}
                </CountUp>
              )
            }
          </React.Fragment>
        )
      }
    </Root>
  );
};

export default Counts;