import React, { useEffect, useState } from 'react';
import Button from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { IconButton, Link, styled, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { actions as walletActions } from '../services/wallet';
import { actions as whitelistActions } from '../services/whitelist';

import isEmail from 'validator/es/lib/isEmail';


interface Props {
  open: boolean;
  onClose: () => void;
}

const CloseButton = styled(IconButton)({
  position: 'absolute',
  top: 5,
  right: 5,
})

const WhiteListDialog: React.FC<Props> = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const wallet = useSelector((state: RootState) => state.wallet.account);
  const whitelist = useSelector((state: RootState) => state.whitelist);

  const [ address, setAddress ] = useState('');
  const [ email, setEmail ] = useState('');
  const [ emailError, setEmailError ] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddress(event.target.value);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };



  useEffect(() => {
    setAddress(wallet ?? '');
  }, [ wallet ]);

  const connectWallet = () => {
    dispatch(walletActions.connectWallet({}));
  }

  const onSubmit = () => {
    if (email && !isEmail(email)) {
      setEmailError("Invalid email address");
      return;
    }

    setEmailError('');

    dispatch(whitelistActions.submitToWhitelist(address, email));
  }


  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Join the whitelist
        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>

      </DialogTitle>
      {
        !whitelist.added ? (
          <React.Fragment>
            <DialogContent>
              <DialogContentText sx={{ mb: 2 }}>
                Join the whitelist to gain exclusive early pre-sale access.
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                inputProps={{
                  spellCheck: "false"
                }}
                id="name"
                label="Your Ethereum Address *"
                type="eth_adress"
                fullWidth
                variant="outlined"
                value={address}
                onChange={handleChange}
                error={Boolean(whitelist.error)}
                helperText={whitelist.error}
                disabled={whitelist.loading}
              />


              <Typography sx={{ mt: 6 }} variant="body2" color="text.secondary" gutterBottom>
                Optionally provide your email address to get notified once pre-sale starts.
              </Typography>

              <TextField
                margin="dense"
                id="email"
                label="Email address (optional)"
                type="email"
                fullWidth
                variant="outlined"
                value={email}
                onChange={handleEmailChange}
                error={Boolean(emailError)}
                helperText={emailError}
                disabled={whitelist.loading}
              />
              <Typography sx={{ mt: 2 }} variant="caption" color="text.secondary" gutterBottom>
                Once pre-sale starts we will also announce on {' '}
                <Link target="_blank" href="https://twitter.com/hayapenft">Twitter</Link>, {' '}
                <Link target="_blank" href="https://discord.gg/22zPAXdT95">Discord</Link>, {' '}
                <Link target="_blank" href="https://instagram.com/hayapenft">Instagram</Link>, {' '}
                <Link target="_blank" href="https://facebook.com/hayapenft">Facebook</Link> {' '} 
                so make sure to follow.
              </Typography>
            </DialogContent>
            <DialogActions>
              {
                Boolean(wallet) ? null : (
                  <Button onClick={connectWallet}>Connect Wallet</Button>
                )
              }
              <Button
                onClick={onSubmit}
                color="secondary"
                disabled={!Boolean(address)}
                loading={whitelist.loading}
              >
                Submit
              </Button>
            </DialogActions>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <DialogContent>
              <DialogContentText sx={{ mb: 2 }}>
                Thanks for joining our whitelist. Be sure to check back in when the presale starts.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="secondary">Close</Button>
            </DialogActions>
          </React.Fragment>
        )
      }
    </Dialog>
  );
}

export default WhiteListDialog;