import React from "react";
import { useStaticQuery, graphql } from "gatsby"
import { Avatar, Container, Divider, Paper, Stack, styled, Typography } from '@mui/material';

const Root = styled('div')({

});


const PrimaryText = styled('span')(({theme}) => {
  return {
    color: theme.palette.primary.main,
  }
})

const About: React.FC = () => {
  const avatars = useStaticQuery(graphql`
    query {
      pumba: file(relativePath: {in: "avatars/pumba.jpg"}) {
        publicURL
      }
      lyotchik: file(relativePath: {in: "avatars/lyotchik.jpg"}) {
        publicURL
      }
      mog: file(relativePath: {in: "avatars/mog.jpg"}) {
        publicURL
      }
    }
  `);


  console.log('data', avatars);

  return (
    <Container maxWidth="md" sx={{ mt: 5 }} className="about-section">
      <Stack direction="column" spacing={2}>

        <Paper sx={{ padding: 2 }} >
          <Typography paragraph>About</Typography>
          <Divider sx={{ mb: 2 }} />
          
          <Typography paragraph><PrimaryText>Each HayApe is unique</PrimaryText> and no two are exactly alike.
            The combination of a HayApes aesthetics was randomly generated from <PrimaryText>124 hand drawn traits</PrimaryText>.
          </Typography>

          <Typography>
              There are <PrimaryText>9,999 unique</PrimaryText> collectible HayApes with proof of ownership stored on the Ethereum blockchain.
              HayApes are "Non-Fungible Tokens" on Ethereum, and each one was created as an ERC-721 standard token, 
              that powers most digital art and collectibles.
          </Typography>

        </Paper>

        <Paper sx={{ padding: 2 }} >
          <Typography paragraph>The Team</Typography>
          <Divider sx={{ mb: 2 }} />
          <Stack direction="row" spacing={2} justifyContent="space-evenly">
            <Stack alignItems="center" spacing={1} data-aos="zoom-in">
              <Avatar alt="Lyotchik" sx={{ width: 100, height: 100 }} src={avatars.lyotchik.publicURL} />
              <Typography>Lyotchik</Typography>
            </Stack>
            <Stack alignItems="center" spacing={1} data-aos="zoom-in">
              <Avatar alt="Mog" sx={{ width: 100, height: 100 }} src={avatars.mog.publicURL} />
              <Typography>Mog</Typography>
            </Stack>
            <Stack alignItems="center" spacing={1} data-aos="zoom-in">
              <Avatar alt="Pumba" sx={{ width: 100, height: 100 }} src={avatars.pumba.publicURL} />
              <Typography>Pumba</Typography>
            </Stack>
          </Stack>
        </Paper>

      </Stack>
    </Container>
  );
};

export default About;